import React from "react";
import styled from "styled-components";

import { S_BREAKPOINT } from "../../../styles";
import Transition from "../../Transition";
import { Description, Heading } from "../Components";
import Layout from "../Layout";

import PilotClientInterface from "./PilotClientInterface";

const Steps = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  justify-content: center;

  @media (max-width: ${S_BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Step = styled.span`
  background-color: #ffe6f4;
  color: #ff8592;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: var(--xBold);
  margin-bottom: var(--l);
`;

const StepContainer = styled.div`
  max-width: 25%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${S_BREAKPOINT}px) {
    margin-bottom: var(--l);
    max-width: none;
  }
`;

const StepDescription = styled.p`
  text-align: center;
  font-weight: var(--bold);
  font-size: var(--sText);
  line-height: 1.25rem;
  color: var(--landingTextBlue);
`;

const SectionHeader = styled(Heading)`
  --from: #9849ff;
  --to: #ff5100;
`;

const SectionDescription = styled(Description)`
  --color: var(--landingTextBlue);
  margin-bottom: var(--xxl);
`;

const start = { opacity: 0, y: 30 };
const end = { opacity: 1, y: 0 };

const PilotClient = () => {
  return (
    <Layout guidesColor="rgba(0, 0, 0, 0)">
      <Transition start={start} end={end}>
        <SectionHeader>Connect with one of our simple apps</SectionHeader>
        <SectionDescription>
          VATSIM offers simple, fast and easy to use clients for all major
          flight simulators: Microsoft Flight Simulator, Prepar3D, X-Plane and
          FlightGear
        </SectionDescription>
        <Steps>
          <StepContainer>
            <Step>1</Step>
            <StepDescription>File a flightplan</StepDescription>
          </StepContainer>
          <StepContainer>
            <Step>2</Step>
            <StepDescription>
              Connect with one of our easy to use clients
            </StepDescription>
          </StepContainer>
          <StepContainer>
            <Step>3</Step>
            <StepDescription>
              Start flying on the VATSIM Network!
            </StepDescription>
          </StepContainer>
        </Steps>
        <PilotClientInterface />
      </Transition>
    </Layout>
  );
};

export default PilotClient;
