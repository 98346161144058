import React from "react";

import Transition from "../../Transition";
import YoutubeEmbed from "../../YouTube";
import { Description, Heading } from "../Components";
import Layout from "../Layout";

const start = { opacity: 0, y: 30 };
const end = { opacity: 1, y: 0 };

const FlyOnline = () => {
  return (
    <Layout guidesColor="rgba(0, 0, 0, 0)">
      <Transition start={start} end={end}>
        <Heading>Fly online with thousands of others</Heading>
        <Description>
          Enter a realistic and dynamic online simulator experience with air
          traffic controllers and pilots
        </Description>
        <YoutubeEmbed embedId="rysWgPVfwSM" />
      </Transition>
    </Layout>
  );
};

export default FlyOnline;
