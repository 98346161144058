import React, { useEffect, useState } from "react";

import { useInterval } from "../../hooks";
import Flight from "../../types/flight";

import Path from "./Path";

const FLIGHTS_TO_DISPLAY = 75;

interface PathsProps {
  radius: number;
  onHover: (x: Flight) => void;
  onHoverOut: () => void;
}

const Paths = ({ radius, onHover, onHoverOut }: PathsProps) => {
  const [flights, setFlights] = useState<Flight[]>();
  const [currentFlights, setCurrentFlights] = useState<Flight[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${process.env.GATSBY_API_URL}/api/map`);
        const data: Flight[] = await response.json();
        setFlights(data);
      } catch (error) {
        console.log(`Error fetching flights: ${error}`);
      }
    })();
  }, []);

  useInterval(() => {
    if (!flights) {
      return;
    }
    setCurrentIndex(currentIndex + 1);

    if (currentIndex === 0) {
      setCurrentFlights(flights.slice(0, 5));
      setCurrentIndex(5);
    } else if (currentIndex === flights.length) {
      setCurrentIndex(1);
    } else if (currentFlights.length === FLIGHTS_TO_DISPLAY - 1) {
      setCurrentFlights([...currentFlights.slice(1), flights[currentIndex]]);
    } else {
      setCurrentFlights([...currentFlights, flights[currentIndex]]);
    }
  }, 500);

  return (
    <group>
      {currentFlights.length > 0 &&
        currentFlights?.map(
          (flight) =>
            flight && (
              <Path
                key={flight.callsign}
                onHoverOver={() => onHover(flight)}
                onHoverOut={() => onHoverOut()}
                radius={radius}
                start={flight.departure.coords}
                end={flight.arrival.coords}
              />
            )
        )}
    </group>
  );
};

export default Paths;
