import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Info, X } from "react-feather";
import styled from "styled-components";

const Container = styled(motion.div)`
  width: 100vw;
  background: #1d2638;
  color: var(--landingTextLight);
  overflow: hidden;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 var(--gutter);

  & > svg {
    cursor: pointer;
  }
`;

const InfoContainer = styled.div`
  display: flex;

  & > svg {
    margin-right: var(--m);
  }
`;

const Description = styled.p`
  & > a {
    font-weight: var(--bold);
    color: inherit;
    border-bottom: 1px solid white;
    transition: all 100ms linear;

    &:hover {
      opacity: 0.8;
    }
  }
`;

interface Status {
  page: {
    name: string;
    url: string;
    status: "UP" | "HASISSUES" | "UNDERMAINTENANCE";
  };
}

const variants = {
  initial: {
    height: 0,
  },
  open: {
    height: 40,
  },
};

const Status = () => {
  const animate = useAnimation();
  const [status, setStatus] = useState<Status | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          "https://vatsim.instatus.com/summary.json"
        );
        const data = await response.json();
        setStatus(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (status?.page.status === "HASISSUES" && status) {
      animate.start(variants.open);
    }
  }, [status]);

  return (
    <Container
      variants={variants}
      initial={variants.initial}
      animate={animate}
      transition={{ bounce: false, easings: "linear" }}
    >
      <Content>
        <div />
        {status?.page.status === "HASISSUES" && (
          <InfoContainer>
            <Info size={14} />
            <Description>
              VATSIM is currently experiencing an outage.{" "}
              <a href={status?.page.url}>
                Visit our status page for more information.
              </a>
            </Description>
          </InfoContainer>
        )}
        <X
          role="button"
          aria-label="close status notification"
          onClick={() => animate.start(variants.initial)}
        />
      </Content>
    </Container>
  );
};

export default Status;
