import React from "react";
import styled from "styled-components";

import { M_BREAKPOINT, S_BREAKPOINT } from "../../../styles";
import Button from "../../Button";
import SvgLogo from "../../SvgLogo";

import Navigation from "./Nav";

export const HEADER_HEIGHT = "7.5rem";

const Container = styled.header`
  width: 100%;
  height: ${HEADER_HEIGHT};
  margin: auto;
  max-width: 1400px;
  padding: var(--xl) var(--gutter);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  @media (max-width: ${S_BREAKPOINT}px) {
    padding: var(--l);
    flex-direction: column;

    & > svg {
      /* max-height: 40px; */
      padding-bottom: var(--l);
    }
  }
`;

const ButtonContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: space-between;

  a {
    display: flex;
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    display: none;
  }
`;

const Header = () => {
  return (
    <Container>
      <SvgLogo height={80} ignoreDarkMode />
      <Navigation />
      <ButtonContainer>
        <a href="https://donate.vatsim.net">
          <Button
            label="Donate"
            textColor="var(--landingTextLight)"
            color="rgba(0, 0, 0, 0)"
          />
        </a>
        <a href="https://my.vatsim.net/register">
          <Button
            label="Join"
            textColor="var(--landingTextBlue)"
            color="var(--landingPrimary)"
          />
        </a>
      </ButtonContainer>
    </Container>
  );
};

export default Header;
