import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { useMousePosition } from "../../hooks";
import { isSSR } from "../../hooks/helpers";
import Flight from "../../types/flight";

const Container = styled.div`
  pointer-events: none;
  position: absolute;
  border-radius: var(--m);
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  padding: var(--l);
  z-index: 2;
  color: var(--landingTextLight);
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: var(--xBold);
  margin-bottom: var(--s);
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: var(--landingTextLightSecondary);
`;

const Dot = styled.span`
  color: var(--landingAccent);
`;

interface PathPopupProps {
  activePath: Flight | undefined;
  offsets: number[];
}

const PathPopup = ({ activePath, offsets }: PathPopupProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const { x, y } = useMousePosition();
  const [position, setPosition] = useState("");
  const [popupWidth, setPopupWidth] = useState(0);

  const date = useMemo(() => {
    if (!activePath) {
      return 0;
    }
    return Number(
      (
        Math.abs(
          new Date(activePath.onlineTime).getTime() - new Date().getTime()
        ) /
        (60 * 60 * 1000)
      ).toFixed()
    );
  }, [activePath]);

  useEffect(() => {
    const { current } = popupRef;

    if (current) {
      const { width } = current?.getBoundingClientRect();

      setPopupWidth(width);
    }
  }, [activePath, popupRef]);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    const xPosition = x - offsets[0] + 10;
    const yPosition = y - offsets[1] + 10;
    // client width includes scrollbar width
    const CANVAS_WIDTH = document.body.clientWidth - offsets[0];
    const DISTANCE_TO_RIGHT = CANVAS_WIDTH - xPosition - 10;

    if (DISTANCE_TO_RIGHT <= popupWidth && window.innerWidth > 1000) {
      // if popup overflows on desktop set X to 10px from right edge
      setPosition(`${offsets[0] - popupWidth - 10}px, ${yPosition}px`);
    } else if (DISTANCE_TO_RIGHT <= popupWidth && window.innerWidth < 1000) {
      setPosition(
        `calc(${
          window.innerWidth - popupWidth - 20
        }px + calc(var(--gutter) * -1)), ${yPosition}px`
      );
    } else {
      setPosition(`${xPosition}px, ${yPosition}px`);
    }
  }, [x, y]);

  return (
    <>
      {activePath && (
        <Container
          ref={popupRef}
          style={{
            transform: `translate(${position})`,
          }}
        >
          <Title>{activePath.callsign}</Title>
          <Description>
            {activePath.aircraftType} <Dot> • </Dot> {activePath.departure.icao}
            -{activePath.arrival.icao}, departed {date !== 0 && date}{" "}
            {date !== 0 ? "hour" : "just now"}
            {date > 1 && "s"}
            {date > 0 && " ago"}
          </Description>
        </Container>
      )}
    </>
  );
};

export default PathPopup;
