import React from "react";
import styled from "styled-components";

import { M_BREAKPOINT } from "../../../styles";
import Button from "../../Button";
import { Heading } from "../Components";
import Layout from "../Layout";

const SectionHeader = styled(Heading)`
  --from: var(--accent);
  --to: var(--vatsimGreen);
  margin-bottom: var(--l);
`;

const ButtonContainer = styled.a`
  width: 100%;
  max-width: 300px;

  & > button {
    width: 100%;
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    max-width: none;
  }
`;

const Ready = () => {
  return (
    <Layout guidesColor="rgba(0,0,0,0)">
      <SectionHeader>Ready to start flying online?</SectionHeader>
      <ButtonContainer href="https://my.vatsim.net/register">
        <Button label="Join Now" lg color="white" />
      </ButtonContainer>
    </Layout>
  );
};

export default Ready;
