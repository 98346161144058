import { motion } from "framer-motion";
import styled from "styled-components";

import {
  absoluteFillObject,
  M_BREAKPOINT,
  S_BREAKPOINT,
} from "../../../styles";

export const Container = styled.figure`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--xxl);

  @media (max-width: ${S_BREAKPOINT}px) {
    padding: var(--xxl) 0;
  }

  @media (max-width: 400px) {
    display: none;
  }
`;

export const Client = styled.div`
  position: relative;
  height: 300px;
  width: 80vw;
  max-width: 875px;
  border-radius: var(--l);
  background-color: var(--landingPrimary);
  box-shadow: 0 13px 27px -3px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  padding: var(--xl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${M_BREAKPOINT}px) {
    width: 100vw;
    max-width: auto;
  }

  // Some CSS vars must be written in same format as siblings for compatibility
  // with framer-motion
  --halfClientWidth: min(437.5px, 40vw);
  --cursorSize: 2.5rem;
  --halfCursorSize: 1.5rem;
  // half button width - half cursor size
  --buttonCenter: min(2.5rem, 3rem);
  --callsignButtonY: min(5%, 100%);
`;

export const ModalButton = styled(motion.div)`
  background-color: var(--landingTertiary);
  width: 8rem;
  padding: var(--m);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowContainer = styled.div`
  background-color: var(--landingPrimary);
  padding: var(--s);
  border-radius: 50%;
  display: flex;
  align-items: center;

  & > svg {
    stroke: var(--accent);
  }
`;

export const Lists = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: var(--l);
`;

export const ControllerList = styled.div`
  flex: 0.25;
  height: 100%;
  background-color: var(--landingTertiary);
  border-radius: 0.5rem;
  margin-right: var(--l);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: var(--m);

  @media (max-width: ${S_BREAKPOINT}px) {
    display: none;
  }
`;

export const Controller = styled.div`
  height: 1rem;
  background-color: #dbdbdd;
`;

export const ChatBox = styled.div`
  flex: 0.85;
  background-color: var(--landingTertiary);
  border-radius: 0.5rem;
  padding: var(--m);
  display: flex;
  flex-direction: column;

  @media (max-width: ${S_BREAKPOINT}px) {
    flex: 1;
  }
`;

export const Cursor = styled(motion.img)`
  position: absolute;
  z-index: 3;
  height: var(--cursorSize);
  width: var(--cursorSize);
  transform-origin: center;
`;

export const ModalContainer = styled(motion.div)`
  ${absoluteFillObject}

  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: var(--l);
`;

export const Modal = styled(motion.div)`
  ${absoluteFillObject}

  margin: auto;
  width: 40%;
  min-width: 310px;
  height: 90%;
  border-radius: var(--l);
  overflow: hidden;
  background-color: var(--landingPrimary);
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  background-color: var(--landingTertiary);
  width: 100%;
  height: 15%;
  padding: var(--l);
  display: flex;
  align-items: center;
  font-weight: var(--bold);
  color: var(--landingTextBlue);
`;

export const ModalContent = styled.div`
  padding: var(--l);
`;

export const Label = styled(motion.div)`
  font-size: var(--sText);
  text-transform: uppercase;
  color: var(--lightTextColor);
  margin-bottom: var(--xs);
`;

export const Input = styled.input<{ focused: boolean }>`
  width: 100%;
  height: 2.5rem;
  padding: 0 var(--m);
  border-radius: var(--m);
  border: 1px solid #dbdbdd;
  background-color: var(--landingPrimary);
  margin-bottom: var(--ml);
  pointer-events: none;

  box-shadow: ${({ focused }) =>
    focused
      ? "0 0 0 0.2em rgba(18, 55, 216, 0.5)"
      : "0 0 0 0.2em rgba(18, 55, 216, 0)"};
`;

export const ButtonContainer = styled(motion.div)<{ focused: boolean }>`
  & > button {
    width: 100%;
    padding: var(--ml);
    box-shadow: ${({ focused }) =>
      focused
        ? "0 0 0 0.2em rgba(18, 55, 216, 0.5)"
        : "0 0 0 0.2em rgba(18, 55, 216, 0)"};
  }
`;
