import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Hero from "../components/LandingSections/Hero";
import PilotClient from "../components/LandingSections/PilotClient";
import Audio from "../components/LandingSections/Audio";
import Footer from "../components/Footer";
import Ready from "../components/LandingSections/Ready";
import SEO from "../components/Seo";
import Status from "../components/Status";
import FlyOnline from "../components/LandingSections/FlyOnline";
import background from "../images/svg/background_blur.svg";

const Container = styled.div`
  --landingBg: #040d21;
  --landingTextBlue: #0b2549;
  --landingTextLight: #ffffff;
  --landingTextDark: #000000;
  --landingTextLightSecondary: #e1e1e1;
  --landingPrimary: #ffffff;
  --landingTertiary: #ebedf0;
  --landingAccent: #3d4fe0;
  --landingAccentOpaque: rgba(140, 150, 221, 0.2);
  --landingBorder: rgba(0, 0, 0, 0.1);
`;

const HeroContainer = styled.div`
  background-image: url(${background});
  background-color: var(--landingBg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const IndexPage = () => {
  return (
    <Container>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <SEO />
      <Status />
      <HeroContainer>
        <Hero />
        <FlyOnline />
      </HeroContainer>
      <PilotClient />
      <Audio />
      <Ready />
      <Footer />
    </Container>
  );
};

export default IndexPage;
