import React, { ReactNode } from "react";
import styled from "styled-components";

import { S_BREAKPOINT } from "../../styles";
import Guides from "../Guides";

const SectionContainer = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  padding: 4rem var(--gutter);
  z-index: 1;
  position: relative;

  @media (max-width: ${S_BREAKPOINT}px) {
    padding: 2rem var(--gutter);
  }
`;

interface LayoutProps {
  children: ReactNode;
  guidesColor: string;
  bgColor?: string;
}

const Layout = ({ children, guidesColor, bgColor }: LayoutProps) => {
  return (
    <SectionContainer bgColor={bgColor}>
      <Guides color={guidesColor} />
      <Container>{children}</Container>
    </SectionContainer>
  );
};

export default Layout;
