import React from "react";

interface SphereProps {
  radius: number;
  positions: Float32Array;
}

const Sphere = ({ radius, positions }: SphereProps) => {
  return (
    <group>
      <mesh>
        <sphereGeometry args={[radius - 1, 128, 128]} />
        <meshStandardMaterial
          roughness={0.9}
          attach="material"
          color="#15193d"
        />
      </mesh>
      <points position={[0, 0, 0]} rotation={[0, 0, 0]}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attachObject={["attributes", "position"]}
            count={positions.length / 3}
            array={positions}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial
          attach="material"
          size={2.5}
          sizeAttenuation
          color="white"
        />
      </points>
    </group>
  );
};

export default Sphere;
