import { motion, Target, Transition as T, useAnimation } from "framer-motion";
import React, { ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
`;

interface TransitionProps {
  children: ReactNode;
  start: Target;
  end: Target;
  threshold?: number;
}

const Transition = ({ children, start, end, threshold }: TransitionProps) => {
  const [ref, inView] = useInView({
    threshold: threshold ?? 0.25,
    triggerOnce: true,
  });
  const animate = useAnimation();
  const config: T = { bounce: false };

  useEffect(() => {
    if (inView) {
      animate.start(end, config);
    } else {
      animate.start(start, config);
    }
  }, [inView]);

  return (
    <Container ref={ref} animate={animate} initial={start}>
      {children}
    </Container>
  );
};

export default Transition;
