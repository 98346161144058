import React from "react";
import { Canvas } from "@react-three/fiber";
import styled from "styled-components";

import { absoluteFillObject, S_BREAKPOINT } from "../../../styles";
import Guides, { Copy, GuideLayout } from "../../Guides";
import { Description, Heading } from "../Components";

import Waves from "./Waves";

const marketingText = [
  {
    label: "Modern Audio",
    text: "best in class codec simulating both VHF and HF radio",
  },
  {
    label: "Ranging",
    text: "radio signal that degenerates accurately with distance",
  },
  {
    label: "2000+",
    text: "concurrent transceivers online",
  },
  {
    label: "10,000+",
    text: "realistically simulated radio stations",
  },
] as const;

const Container = styled.section`
  position: relative;
  padding-top: 4rem;
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${S_BREAKPOINT}px) {
    padding-top: 2rem;
  }

  & > div {
    min-height: 400px;
  }
`;

const Content = styled.div`
  max-width: 1400px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CanvasContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  pointer-events: none;
`;

const SectionHeading = styled(Heading)`
  --from: #8a9fff;
  --to: #b6fbff;
`;

const Footer = styled.section`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;

  & > :first-child {
    z-index: 2;
  }
`;

const FooterContent = styled.div`
  position: relative;
  padding-bottom: var(--xl);

  & > :first-child {
    z-index: 2;
  }

  &:before {
    position: absolute;
    content: "";
    width: 100vw;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 2));
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

const Audio = () => {
  return (
    <Container>
      <Content>
        <SectionHeading>Audio for VATSIM</SectionHeading>
        <Description>Meet VATSIM's next generation audio system</Description>
        <Footer>
          <Guides color="rgba(255, 255, 255, 0.35)" />
          <FooterContent>
            <GuideLayout>
              {marketingText.map(({ label, text }, i) => (
                <Copy
                  color="rgba(255, 255, 255, 1)"
                  header={label}
                  text={text}
                  key={i}
                />
              ))}
            </GuideLayout>
          </FooterContent>
        </Footer>
      </Content>
      <CanvasContainer>
        <Canvas
          style={{ zIndex: 0 }}
          camera={{
            position: [0, 150, 1000],
            far: 20000,
          }}
        >
          <Waves />
        </Canvas>
      </CanvasContainer>
    </Container>
  );
};

export default Audio;
