import React from "react";
import styled from "styled-components";

import { M_BREAKPOINT, S_BREAKPOINT } from "../../../styles";
import { ButtonProps } from "../../Button";

const Container = styled.a`
  width: 100%;

  @media (max-width: ${M_BREAKPOINT}px) {
    display: flex;
    justify-content: center;
  }
`;

const Button = styled.button`
  ${ButtonProps}
  padding: var(--l);
  border-radius: var(--m);
  background-image: linear-gradient(180deg, #38e092, var(--vatsimGreen));
  color: var(--landingTextLight);

  @media (max-width: ${S_BREAKPOINT}px) {
    width: 100%;
  }

  &:focus {
    box-shadow: 0 0 0 0.2em rgba(46, 164, 79, 0.4);
  }

  ::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--m);
    background-image: linear-gradient(180deg, #2ab976, #249c64);
    z-index: -1;
    transition: opacity 200ms linear;
    opacity: 0;
  }

  &:hover {
    ::before {
      opacity: 1;
    }
  }
`;

interface HeroButtonProps {
  onClick: () => void;
  label: string;
}

const HeroButton = ({ onClick, label }: HeroButtonProps) => {
  return (
    <Container
      href="https://my.vatsim.net/register"
      aria-label="sign up for vatsim"
    >
      <Button onClick={() => onClick()}>{label}</Button>
    </Container>
  );
};

export default HeroButton;
