import React, { Children, ReactNode } from "react";
import styled from "styled-components";

import { M_BREAKPOINT, S_BREAKPOINT } from "../../styles";

const Container = styled.div`
  padding: 0 var(--gutter);
  display: grid;
  grid: 1fr / repeat(4, 1fr);
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media (max-width: ${M_BREAKPOINT}px) {
    grid: 1fr / repeat(2, 1fr);
    grid-row-gap: var(--xl);
    padding-bottom: var(--xl);
  }

  @media (max-width: ${S_BREAKPOINT}px) {
    padding-bottom: 0;
  }
`;

const Child = styled.div`
  @media (max-width: ${S_BREAKPOINT}px) {
    &:nth-last-of-type(-n + 2) {
      display: none;
    }
  }
`;
interface GuideLayoutProps {
  children: ReactNode;
}

const GuideLayout = ({ children }: GuideLayoutProps) => {
  return (
    <Container>
      {Children.map(children, (child) => (
        <Child>{child}</Child>
      ))}
    </Container>
  );
};

export default GuideLayout;
