import React, { useCallback, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { TubeBufferGeometry } from "three";

import { buildCurve } from "./helpers";

interface PathProps {
  radius: number;
  onHoverOver: () => void;
  onHoverOut: () => void;
  start: number[];
  end: number[];
}

const Path = ({ radius, onHoverOver, onHoverOut, start, end }: PathProps) => {
  const geometryRef = useRef<TubeBufferGeometry>(null);
  const [hover, setHover] = useState(false);

  const [curve] = useState(() => buildCurve(start, end, radius));

  const handleHoverOver = useCallback(() => {
    setHover(true);
    onHoverOver();
    document.body.style.cursor = "pointer";
  }, []);

  const handleHoverOut = useCallback(() => {
    setHover(false);
    onHoverOut();
    document.body.style.cursor = "auto";
  }, []);

  const [startTime] = useState(Date.now());

  useFrame(() => {
    const timeElapsed = Date.now() - startTime;

    const progress = timeElapsed / 1000;
    const removalProgress = timeElapsed / 5000;

    if (progress < 1) {
      const drawRangeCount = progress * 3000;
      return geometryRef.current?.setDrawRange(0, drawRangeCount);
    }

    if (removalProgress > 1 && removalProgress < 2) {
      const prog = removalProgress - 1;
      geometryRef.current?.setDrawRange(0, 3000 - prog * 3000);
    }
  });

  const [drawRange] = useState({ start: 0, count: 1 });

  return (
    <group>
      <mesh>
        <tubeBufferGeometry
          drawRange={drawRange}
          ref={geometryRef}
          args={[curve, 44, 0.4, 8]}
        />
        <meshStandardMaterial color={hover ? "#FFF" : "#3d4fe0"} />
      </mesh>
      {/* render a duplicate arc with a greater radius and less complex geometry
       to increase click hitbox size */}
      <mesh
        onPointerOver={() => handleHoverOver()}
        onPointerOut={() => handleHoverOut()}
      >
        <tubeBufferGeometry
          drawRange={drawRange}
          ref={geometryRef}
          args={[curve, 10, 5, 4]}
        />
        <meshStandardMaterial transparent opacity={0} />
      </mesh>
    </group>
  );
};

export default Path;
