import { geoInterpolate } from "d3-geo";
import { CubicBezierCurve3, Vector3 } from "three";

const GLOBE_WIDTH = 4098 / 2;
const GLOBE_HEIGHT = 1968 / 2;

export const dataToSphere = (x: number, y: number, globeRadius: number) => {
  const alpha = ((x - GLOBE_WIDTH) / GLOBE_WIDTH) * -180;
  const delta = ((y - GLOBE_HEIGHT) / GLOBE_HEIGHT) * -90;

  const latitude = (alpha * Math.PI) / 180;
  const longitude = (delta * Math.PI) / 180;
  const radius = Math.cos(longitude) * globeRadius;

  return [
    Math.cos(latitude) * radius,
    Math.sin(longitude) * globeRadius,
    Math.sin(latitude) * radius,
  ];
};

export const toXYZ = (lat: number, lon: number, radius: number) => {
  const PHI = (90 - lat) * (Math.PI / 180);
  const THETA = (lon + 180) * (Math.PI / 180);

  const x = radius * Math.sin(PHI) * Math.cos(THETA) * -1;
  const y = radius * Math.cos(PHI);
  const z = radius * Math.sin(PHI) * Math.sin(THETA);

  return new Vector3(x, y, z);
};

type coord = [number, number] | number[];

export const buildCurve = (start: coord, end: coord, radius: number) => {
  const startXYZ = toXYZ(start[0], start[1], radius);
  const endXYZ = toXYZ(end[0], end[1], radius);

  const d3Interpolate = geoInterpolate([start[1], start[0]], [end[1], end[0]]);

  const control1 = d3Interpolate(0.25);
  const control2 = d3Interpolate(0.75);

  // Set the arc height to 1/4 the distance between points
  const arcHeight = startXYZ.distanceTo(endXYZ) * 0.4 + radius;
  const controlXYZ1 = toXYZ(control1[1], control1[0], arcHeight);
  const controlXYZ2 = toXYZ(control2[1], control2[0], arcHeight);

  return new CubicBezierCurve3(startXYZ, controlXYZ1, controlXYZ2, endXYZ);
};
