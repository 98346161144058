import React from "react";
import styled, { css } from "styled-components";

import { S_BREAKPOINT } from "../styles";

interface YoutubeEmbedProps {
  embedId: string;
}

const Container = styled.section`
  position: relative;
  height: 0;
  width: 100%;
  margin-top: -2.5%;
  margin-bottom: -5%;
  padding-bottom: 56.25%;
  border-radius: var(--m);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position: center;

  @media (max-width: ${S_BREAKPOINT}px) {
    margin-top: var(--xl);
    margin-bottom: 0;
  }
`;

const center = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const Frame = styled.iframe`
  ${center}
  width: 75%;
  height: 75%;
  border-radius: var(--m);
  box-shadow: 0 0 65px rgb(36 131 197 / 40%), 0 0 0 1px hsl(0deg 0% 100% / 10%),
    0 2px 2px rgb(0 0 0 / 3%), 0 4px 4px rgb(0 0 0 / 4%),
    0 10px 8px rgb(0 0 0 / 5%), 0 15px 15px rgb(0 0 0 / 6%),
    0 30px 30px rgb(0 0 0 / 7%), 0 70px 65px rgb(0 0 0 / 9%);

  @media (max-width: ${S_BREAKPOINT}px) {
    width: 97.5%;
    height: 100%;
  }
`;

const YoutubeEmbed = ({ embedId }: YoutubeEmbedProps) => (
  <Container>
    <Frame
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </Container>
);

export default YoutubeEmbed;
