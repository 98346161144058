import React, { useEffect, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { BufferGeometry, Color, Points, ShaderMaterial } from "three";

import { fragmentShader, vertexShader } from "./shaders";

interface Attribute {
  array: Float32Array;
  needsUpdate: boolean;
}

type ABufferGeometry = typeof BufferGeometry & {
  attributes: {
    position: Attribute;
    scale: Attribute;
  };
};

interface APoints extends Points {
  __r3f: {
    objects: [ABufferGeometry, ShaderMaterial];
  };
}

const NUM_PARTICLES = 2500;

const Waves = () => {
  const waves = useRef<APoints>(null);
  const [nodes, setNodes] = useState<Float32Array>();
  const [scale, setScale] = useState<Float32Array>();

  useEffect(() => {
    const positions = new Float32Array(NUM_PARTICLES * 3);
    const scales = new Float32Array(NUM_PARTICLES);

    let i = 0;
    let j = 0;

    for (let ix = 0; ix < 50; ix++) {
      for (let iy = 0; iy < 50; iy++) {
        positions[i] = ix * 100 - (50 * 100) / 2; // x
        positions[i + 1] = 0; // y
        positions[i + 2] = iy * 100 - (50 * 100) / 2; // z

        scales[j] = 1;

        i += 3;
        j++;
      }
    }

    setNodes(positions);
    setScale(scales);
  }, []);

  useFrame(({ clock }) => {
    if (!waves.current?.__r3f.objects) {
      return;
    }

    const positions = waves.current.__r3f.objects[0].attributes.position.array;
    const scales = waves.current.__r3f.objects[0].attributes.scale.array;

    let i = 0;
    let j = 0;

    for (let ix = 0; ix < 50; ix++) {
      for (let iy = 0; iy < 50; iy++) {
        positions[i + 1] =
          Math.sin((ix + clock.elapsedTime) * 0.3) * 50 +
          Math.sin((iy + clock.elapsedTime) * 0.5) * 50;

        scales[j] =
          (Math.sin((ix + clock.elapsedTime) * 0.3) + 1) * 5 +
          (Math.sin((iy + clock.elapsedTime) * 0.5) + 1) * 5;

        i += 3;
        j++;
      }
    }
    waves.current.__r3f.objects[0].attributes.position.needsUpdate = true;
    waves.current.__r3f.objects[0].attributes.scale.needsUpdate = true;
  });

  return (
    <points ref={waves} rotation={[0, 15, 0]}>
      <bufferGeometry attach={"geometry"}>
        <bufferAttribute
          attachObject={["attributes", "position"]}
          array={nodes}
          count={nodes ? nodes.length / 3 : 1}
          itemSize={3}
        />
        <bufferAttribute
          attachObject={["attributes", "scale"]}
          array={scale}
          count={scale ? scale.length : 1}
          itemSize={1}
        />
      </bufferGeometry>
      <shaderMaterial
        attach="material"
        args={[
          {
            uniforms: {
              color: { value: new Color("#6171eb") },
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
          },
        ]}
      />
    </points>
  );
};

export default Waves;
