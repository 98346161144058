import React from "react";
import styled from "styled-components";

const Container = styled.section`
  padding: 0 var(--l);
  position: relative;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: var(--xl);
    width: 1px;
    background-color: #3d4fe0;
  }
`;

const Title = styled.h1`
  color: #ffffff;
  font-size: 1.75rem;
  font-weight: var(--bold);
  margin-bottom: var(--m);
`;

const Text = styled.p<{ color?: string }>`
  line-height: 1.25rem;
  color: ${({ color }) => (color ? color : "inherit")};
`;

interface CopyProps {
  header: string;
  text: string;
  color?: string;
}

const Copy = ({ header, text, color }: CopyProps) => {
  return (
    <Container>
      <header>
        <Title>{header}</Title>
      </header>
      <section>
        <Text color={color}>{text}</Text>
      </section>
    </Container>
  );
};

export default Copy;
