import { PerspectiveCamera } from "@react-three/drei";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Canvas } from "@react-three/fiber";
import styled from "styled-components";

import { useWindowSize } from "../../hooks";
import { M_BREAKPOINT } from "../../styles";
import Flight from "../../types/flight";
import { HEADER_HEIGHT } from "../LandingSections/Header";

import Controls from "./Controls";
import { dataToSphere } from "./helpers";
import Lights from "./Lights";
import PathPopup from "./PathPopup";
import Paths from "./Paths";
import coords from "./points.json";
import Sphere from "./Sphere";

const Container = styled.aside`
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: visible;

  @media (max-width: ${M_BREAKPOINT}px) {
    z-index: -1;
  }
`;

const CanvasContainer = styled.div`
  height: 100vh;
  min-height: 650px;
  max-height: 800px;
  width: 100%;
  margin-top: calc(${HEADER_HEIGHT} * -1);

  @media (max-width: 1400px) {
    width: 50vw;
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    width: 100%;
    z-index: -1;
  }
`;

const GLOBE_RADIUS = 200;

const Globe = () => {
  const canvasRef = useRef<HTMLElement>(null);
  const { width, height } = useWindowSize();
  const [rotate, setRotate] = useState(true);
  const [position, setPosition] = useState<number[]>([]);
  const [activePath, setActivePath] = useState<Flight>();

  useEffect(() => {
    const { current } = canvasRef;

    if (current) {
      const { x, y } = current.getBoundingClientRect();
      setPosition([x, y]);
    }
  }, [canvasRef, width, height]);

  const positions = useMemo(() => {
    const sphereCoords = coords.map(([x, y]) =>
      dataToSphere(x, y, GLOBE_RADIUS)
    );

    return new Float32Array(sphereCoords.flat());
  }, []);

  const handleHover = useCallback((pathInfo: Flight) => {
    setRotate(false);
    setActivePath(pathInfo);
  }, []);

  const handleHoverOut = useCallback(() => {
    setRotate(true);
    setActivePath(undefined);
  }, []);

  return (
    <Container ref={canvasRef}>
      <PathPopup activePath={activePath} offsets={position} />
      <CanvasContainer>
        <Canvas style={{ overflow: "visible" }}>
          <Controls rotate={rotate} />
          <PerspectiveCamera makeDefault position={[600, 250, 0]}>
            <Lights />
          </PerspectiveCamera>
          <Paths
            radius={GLOBE_RADIUS}
            onHover={(pathInfo) => handleHover(pathInfo)}
            onHoverOut={() => handleHoverOut()}
          />
          <Sphere radius={GLOBE_RADIUS} positions={positions} />
        </Canvas>
      </CanvasContainer>
    </Container>
  );
};

export default Globe;
