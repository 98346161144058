import React from "react";
import styled from "styled-components";

import Globe from "../../Globe";
import Header from "../Header";
import { M_BREAKPOINT, S_BREAKPOINT } from "../../../styles";
import simLogos from "../../../images/svg/sim_logo.svg";

import HeroCopy from "./HeroCopy";

const HeroContainer = styled.div`
  z-index: 1;
  position: relative;
  height: 100vh;
  max-height: 800px;
  min-height: 650px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0.5fr repeat(3, 1fr) 0.75fr;
  justify-content: center;
  align-content: center;
  width: 100%;
  color: var(--textColor);
  overflow: hidden;
  padding-bottom: var(--ml);

  & > :first-child {
    grid-area: 1 / 1 / 2 / 6;
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    background-position: right;
    max-height: none;
  }
`;

const HeroItemContainer = styled.section`
  display: flex;
  position: relative;
  padding: 0 var(--gutter);
  overflow: visible;
  max-width: 1400px;
  justify-content: center;
  grid-area: 2 / 1 / 5 / 6;
  margin: auto;
  max-height: 100%;

  @media (max-width: ${M_BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  @media (max-width: ${S_BREAKPOINT}px) {
    padding: 0;
  }
`;

const HeroItem = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;

  &:first-child {
    padding: 5rem 0;

    @media (max-width: ${M_BREAKPOINT}px) {
      padding: 2.5rem var(--gutter);
    }
  }

  &:last-child {
    @media (max-width: ${M_BREAKPOINT}px) {
      height: 100%;
      width: 100%;
      margin: 0 calc(var(--gutter) * -1);
      margin-bottom: -500px;
    }
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    width: 100%;
  }
`;

const HeroFooter = styled.section`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: var(--s) 0;
  grid-area: 5 / 1 / 6 / 6;
  margin: auto;

  @media (max-width: ${M_BREAKPOINT}px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: ${S_BREAKPOINT}px) {
    margin-bottom: -2rem;

    &:before {
      position: absolute;
      content: "";
      width: 200vw;
      height: 100%;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
      background: linear-gradient(
        180deg,
        var(--landingBg) 80%,
        rgba(0, 0, 0, 0)
      );
    }
  }
`;

const FooterImg = styled.img`
  max-height: 100%;
  max-width: 60%;
  padding: 0 var(--gutter);

  @media (max-width: ${S_BREAKPOINT}px) {
    max-width: 80%;
    z-index: 2;
  }
`;

const Hero = () => {
  return (
    <HeroContainer>
      <Header />
      <HeroItemContainer>
        <HeroItem>
          <HeroCopy />
        </HeroItem>
        <HeroItem>
          <Globe />
        </HeroItem>
      </HeroItemContainer>
      <HeroFooter>
        <FooterImg src={simLogos} />
      </HeroFooter>
    </HeroContainer>
  );
};

export default Hero;
