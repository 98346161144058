import styled from "styled-components";

import { M_BREAKPOINT, S_BREAKPOINT } from "../../styles";

export const Heading = styled.h2`
  --from: #a2facf;
  --to: #64acff;
  font-size: 3.75rem;
  font-weight: var(--xBold);
  text-align: center;
  background: -webkit-linear-gradient(-70deg, var(--from), var(--to));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  line-height: 1.5em;

  @media (max-width: ${M_BREAKPOINT}px) {
    font-size: 3rem;
  }

  @media (max-width: ${S_BREAKPOINT}px) {
    font-size: 2.25rem;
  }
`;

export const Description = styled.p`
  --color: var(--landingTextLight);
  font-weight: var(--bold);
  font-size: 1.25rem;
  text-align: center;
  color: var(--color);
  line-height: 1.6em;
  max-width: min(95vw, 800px);
  margin: 0 auto;
  margin-top: var(--ml);

  @media (max-width: ${M_BREAKPOINT}px) {
    font-size: 1rem;
  }
`;
