import React from "react";
import styled from "styled-components";

import { M_BREAKPOINT } from "../../styles";

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0 var(--gutter);
  z-index: 0;
  pointer-events: none;
  max-width: 1400px;
`;

const GuidesContainer = styled.div`
  position: relative;
  display: grid;
  grid: 1fr / repeat(4, 1fr);
  margin: 0 auto;
  height: 100%;

  @media (max-width: ${M_BREAKPOINT}px) {
    grid: 1fr / repeat(2, 1fr);
  }
`;

const Guide = styled.div<{ color: string }>`
  height: 100%;
  width: 1px;
  background: ${({ color }) => color};
  background-size: 16px 10px, 100% 10px;

  &:last-of-type {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: ${({ color }) => color};
  }
`;

interface GuidesProps {
  color: string;
}

const Guides = ({ color }: GuidesProps) => {
  return (
    <Container aria-hidden>
      <GuidesContainer>
        {[...Array(5).keys()].map((i) => (
          <Guide color={color} key={i} />
        ))}
      </GuidesContainer>
    </Container>
  );
};

export default Guides;
