import { motion, useAnimation } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import cursor from "../../../images/svg/cursor.svg";
import Button from "../../Button";

import {
  ArrowContainer,
  ButtonContainer,
  ChatBox,
  Client,
  Container,
  Controller,
  ControllerList,
  Cursor,
  Input,
  Lists,
  Modal,
  ModalButton,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from "./Styled";
import {
  buttonVariants,
  modalContainerVariants,
  modalVariants,
  mouseVariants,
} from "./variants";

const Message = styled.p`
  font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin-bottom: var(--m);
  line-height: 1.25rem;
  color: var(--landingTextDark);
`;

const Title = styled.div`
  font-weight: var(--bold);
  font-size: 1.25rem;
  color: var(--landingTextBlue);
`;

const ClientTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FLIGHTS = [
  { callsign: "FDX5233", acf: "B763" },
  { callsign: "VIR411Y", acf: "A35K" },
  { callsign: "DAL1789", acf: "B739" },
  { callsign: "UAL285", acf: "B739" },
  { callsign: "DAL256", acf: "B739" },
  { callsign: "IBE2801", acf: "A319" },
  { callsign: "BAW9CG", acf: "B77W" },
  { callsign: "AAL1226", acf: "A321" },
  { callsign: "UAL1743", acf: "B753" },
  { callsign: "N1088G", acf: "PA30" },
  { callsign: "GECAC", acf: "HR20" },
  { callsign: "CFLGW", acf: "RV14" },
];

const sleep = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

const currentDate = new Intl.DateTimeFormat("en-us", {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
}).format(new Date());

const connectVariants = {
  initial: {
    scale: 1,
  },
  pressed: {
    scale: 0.975,
  },
};

const PilotClientInterface = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [callsignFocused, setCallsignFocused] = useState(false);
  const [aircraftFocused, setAircraftFocused] = useState(false);
  const [connectFocused, setConnectFocused] = useState(false);
  const [ref, inView] = useInView({ threshold: 0.75 });
  const mouseAnimation = useAnimation();
  const buttonAnimation = useAnimation();
  const modalAnimation = useAnimation();
  const connectAnimation = useAnimation();
  const [controllers] = useState(
    [...Array(5).keys()].map(
      () => Number((Math.random() * (1 - 0.6) + 0.6).toFixed(1)) * 100
    )
  );
  const [callsign, setCallsign] = useState("");
  const [aircraft, setAircraft] = useState("");

  const fillFields = useCallback(async () => {
    return new Promise(async (resolve) => {
      const { callsign, acf } = FLIGHTS[
        Math.floor(Math.random() * (FLIGHTS.length - 1) + 1)
      ];

      const splitCallsign = callsign.split("");
      const splitAcf = acf.split("");

      setCallsignFocused(true);

      for (const letter of splitCallsign) {
        setCallsign((current) => current + letter);
        await sleep(200);
      }

      setCallsignFocused(false);

      sleep(500);

      setAircraftFocused(true);
      for (const letter of splitAcf) {
        setAircraft((current) => current + letter);
        await sleep(250);
      }
      setAircraftFocused(false);
      resolve(null);
    });
  }, []);

  const resetAnimation = useCallback(() => {
    const override = { duration: 0 };
    setAircraft("");
    setAircraftFocused(false);
    setCallsign("");
    setCallsignFocused(false);
    setConnectFocused(false);
    setIsConnected(false);
    connectAnimation.start("initial", override);
    mouseAnimation.start("initial", override);
    buttonAnimation.start("default", override);
    modalAnimation.start("initial", { duration: 0.2 });
  }, []);

  const runAnimation = useCallback(async () => {
    await mouseAnimation.start({ x: "var(--buttonCenter)", y: 0, opacity: 1 });

    await buttonAnimation.start("pressed");
    await modalAnimation.start("open");
    await buttonAnimation.start("default");
    await mouseAnimation.start({ opacity: 0 }, { transition: 0.1 });
    mouseAnimation.start("initial");
    await fillFields();
    await mouseAnimation.start({ opacity: 1 }, { transition: 0.3 });
    setConnectFocused(true);
    await sleep(500);
    await connectAnimation.start("pressed", { transition: 0.2 });
    await modalAnimation.start("initial");
    mouseAnimation.start({ opacity: 0 });

    setIsConnected(true);
  }, []);

  useEffect(() => {
    // restart animation if ref goes offscreen
    if (!inView) {
      resetAnimation();
    }
    // initialise animation
    if (inView) {
      resetAnimation();
      runAnimation();
    }
  }, [inView]);

  return (
    <Container ref={ref}>
      <Client>
        <Cursor
          src={cursor}
          alt="mouse cursor"
          initial={mouseVariants.initial}
          animate={mouseAnimation}
          variants={mouseVariants}
          transition={{ duration: 1.5 }}
        />
        <ModalContainer
          variants={modalContainerVariants}
          animate={modalAnimation}
          initial={modalContainerVariants.initial}
        >
          <Modal
            variants={modalVariants}
            animate={modalAnimation}
            initial={modalVariants.initial}
          >
            <ModalHeader>Connect to VATSIM</ModalHeader>
            <ModalContent>
              <Input
                focused={callsignFocused}
                placeholder="Callsign"
                value={callsign}
                onChange={() => null}
                disabled
              />
              <Input
                focused={aircraftFocused}
                placeholder="Aircraft Type"
                value={aircraft}
                onChange={() => null}
                disabled
              />
              <ButtonContainer
                focused={connectFocused}
                animate={connectAnimation}
                variants={connectVariants}
              >
                <Button
                  disabled
                  color="var(--landingPrimary)"
                  label={connectFocused ? "Loading.." : "Connect"}
                />
              </ButtonContainer>
            </ModalContent>
          </Modal>
        </ModalContainer>
        <ClientTop>
          <ModalButton
            animate={buttonAnimation}
            variants={buttonVariants}
            initial={buttonVariants.default}
          >
            <ArrowContainer>
              <ArrowRight />
            </ArrowContainer>
          </ModalButton>
          <Title>{isConnected && callsign}</Title>
          <div />
        </ClientTop>

        <Lists>
          <ControllerList>
            {controllers.map((width, i) => (
              <Controller key={i} style={{ width: `${width}%` }} />
            ))}
          </ControllerList>
          <ChatBox>
            {isConnected && (
              <motion.div>
                <Message>[{currentDate}] Connected to network.</Message>
                <Message>
                  [{currentDate}] Thanks for being a part of the community and
                  enjoy your session!
                </Message>
              </motion.div>
            )}
          </ChatBox>
        </Lists>
      </Client>
    </Container>
  );
};

export default PilotClientInterface;
