import React from "react";
import styled from "styled-components";

export const ButtonProps = `
  border: none;
  outline: none;
  padding: var(--m);
  padding-left: var(--ml);
  padding-right: var(--ml);
  cursor: pointer;
  font-weight: var(--bold);
  border-radius: var(--xl);
  font-size: var(--sText);
  transition: box-shadow 200ms ease-in-out;
  min-width: 3rem;
  position: relative;

  &:hover {
    box-shadow: var(--shadowM);
  }

  &:focus {
    box-shadow: "0 0 0 0.2em rgba(18, 55, 216, 0.5)";
  }
`;

type ContainerProps = {
  bgColor: string | undefined;
  textColor: string | undefined;
  lg: boolean | undefined;
};

const Container = styled.button<ContainerProps>`
  ${ButtonProps}

  padding: ${({ lg }) => lg && "var(--l) var(--xl)"};
  font-size: ${({ lg }) => lg && "1.15rem"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "var(--primary)")};
  border: 1px solid
    ${({ textColor }) => (textColor ? textColor : "var(--accent)")};
  color: ${({ textColor }) => (textColor ? textColor : "var(--accent)")};
  transition: all 100ms linear;
  border-radius: ${({ lg }) => (lg ? "var(--l)" : "var(--m)")};
  min-width: 80px;
  pointer-events: ${({ disabled }) => disabled && "none"};

  &:hover {
    opacity: 0.8;
    box-shadow: var(--shadowS);
  }
`;

interface ButtonProps {
  label: string;
  onClick?: () => void;
  color?: string;
  textColor?: string;
  disabled?: boolean;
  lg?: boolean;
}

const Button = ({
  label,
  onClick,
  color,
  textColor,
  disabled,
  lg,
}: ButtonProps) => {
  return (
    <Container
      tabIndex={0}
      onClick={() => onClick && onClick()}
      bgColor={color}
      textColor={textColor}
      disabled={disabled}
      lg={lg}
    >
      {label}
    </Container>
  );
};

export default Button;
