import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import { S_BREAKPOINT } from "../../../styles";

const Container = styled.nav`
  width: 50%;
  overflow-x: auto;

  @media (max-width: ${S_BREAKPOINT}px) {
    width: 100%;
  }
`;

const NavItems = styled.ol`
  display: flex;
  justify-content: space-between;
`;

const Item = styled.li`
  font-weight: var(--bold);
  position: relative;
  cursor: pointer;
  flex: 1;

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--landingTextLight);
    font-size: var(--sText);
    line-height: normal;
  }

  &:hover > div {
    display: flex;
  }
`;

const Nav = () => {
  return (
    <Container>
      <NavItems>
        <Item>
          <Link to="/docs">Documentation</Link>
        </Item>
        <Item>
          <Link to="/events">Events</Link>
        </Item>
        <Item>
          <a href="https://my.vatsim.net/">myVATSIM</a>
        </Item>
      </NavItems>
    </Container>
  );
};

export default Nav;
