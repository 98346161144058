import { OrbitControls } from "@react-three/drei";
import React, { useEffect } from "react";
import { useThree } from "@react-three/fiber";

import { useWindowSize } from "../../hooks";

interface ControlsProps {
  rotate: boolean;
}

const Controls = ({ rotate }: ControlsProps) => {
  const { camera } = useThree();
  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width >= 1300) {
      camera.position.set(600, 250, 0);
    }
    if (width && width <= 1300) {
      camera.position.set(700, 250, 0);
    }
    if (width && width <= 1100) {
      camera.position.set(750, 250, 0);
    }
  }, [width, camera]);

  return (
    <OrbitControls
      enableZoom={false}
      autoRotate={rotate}
      autoRotateSpeed={0.75}
      enablePan={false}
      maxPolarAngle={Math.PI - Math.PI / 3}
      minPolarAngle={Math.PI / 3}
      dampingFactor={0.04}
    />
  );
};

export default Controls;
