export const mouseVariants = {
  initial: {
    opacity: 0,
    x: "var(--halfClientWidth)",
    y: 150,
    scale: 0.7,
  },
  buttonClick: {
    x: "var(--buttonCenter)",
    y: 0,
    scale: 1,
  },
};

export const buttonVariants = {
  default: {
    scale: 1,
    opacity: 1,
  },
  pressed: {
    scale: 0.95,
    opacity: 0.8,
  },
};

export const modalContainerVariants = {
  initial: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
};

export const modalVariants = {
  initial: {
    scale: 0.3,
    opacity: 0.3,
  },
  open: {
    scale: 1,
    opacity: 1,
  },
};
