import React from "react";

const Lights = () => (
  <group>
    <ambientLight intensity={0.5} />
    <directionalLight castShadow intensity={0.75} position={[30, 30, 50]} />
    <directionalLight castShadow intensity={0.25} position={[-30, -30, -50]} />
    <pointLight intensity={0.2} position={[0, 0, 0]} />
  </group>
);

export default Lights;
