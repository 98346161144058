import React from "react";
import styled from "styled-components";

import { M_BREAKPOINT } from "../../../styles";

import HeroButton from "./HeroButton";

const Container = styled.div`
  @media (max-width: ${M_BREAKPOINT}px) {
    margin: 0 auto;
  }
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: var(--xBold);
  color: var(--landingTextLight);
  margin-bottom: var(--s);

  @media (max-width: 1400px) {
    font-size: 2.5rem;
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    font-size: 1.75rem;
    text-align: center;
  }
`;

const HeroBody = styled.p`
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: var(--landingTextLightSecondary);

  @media (max-width: ${M_BREAKPOINT}px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;
    max-width: 800px;
  }
`;

const ButtonContainer = styled.div`
  padding: var(--l) 0;

  @media (max-width: ${M_BREAKPOINT}px) {
    display: flex;
    justify-content: center;
  }
`;

const Hero = () => {
  return (
    <Container>
      <HeroTitle>The International Online Aviation Network</HeroTitle>
      <HeroBody>
        Join the largest online aviation network, and interact with over 100,000
        other virtual pilots and air traffic controllers all around the world
      </HeroBody>
      <ButtonContainer>
        <HeroButton label="Get Started with VATSIM" onClick={() => null} />
      </ButtonContainer>
    </Container>
  );
};

export default Hero;
