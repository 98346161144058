import React from "react";
import { Facebook, GitHub, Instagram, Twitter } from "react-feather";
import styled from "styled-components";

import {
  hrefStyles,
  M_BREAKPOINT,
  FlexPlaceHolder,
  S_BREAKPOINT,
  ML_BREAKPOINT,
} from "../styles";

import Guides from "./Guides";
import SvgLogo from "./SvgLogo";

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f9fc;
  position: relative;
`;

const Content = styled.div`
  padding: 0 var(--gutter);
  width: 100%;
  max-width: 1400px;
  min-height: 300px;
  display: grid;
  grid: 1fr / repeat(4, 1fr);
  color: var(--landingTextBlue);

  @media (max-width: ${M_BREAKPOINT}px) {
    grid: 1fr / repeat(2, 1fr);
    padding-bottom: var(--xl);
  }

  @media (max-width: ${S_BREAKPOINT}px) {
    grid: 1fr / repeat(1, 1fr);
    padding-bottom: var(--m);
  }
`;

const Item = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--xl);
  z-index: 1;

  @media (max-width: ${ML_BREAKPOINT}px) {
    padding: var(--xl) var(--ml);
  }

  @media (max-width: ${M_BREAKPOINT}px) {
    padding: var(--xl) var(--ml);
  }

  & > a {
    width: calc(100%);
    text-overflow: ellipsis;
    word-wrap: none;
  }
`;

const LogosContainer = styled.div`
  margin-top: var(--xl);
  display: flex;
  z-index: 1;

  & > a {
    color: inherit;
    margin-right: var(--l);
  }
`;

const Copyright = styled.p`
  margin-top: auto;
  margin-bottom: var(--l);
  line-height: var(--xl);

  @media (max-width: ${M_BREAKPOINT}px) {
    margin-top: var(--xl);
  }
`;

const Label = styled.p`
  font-weight: var(--bold);
  margin-bottom: var(--xl);
  position: relative;

  @media (max-width: ${M_BREAKPOINT}px) {
    &:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 1px;
      background-color: var(--accent);
      left: calc(var(--ml) * -1);
    }
  }
`;

const Link = styled.a`
  margin-bottom: var(--l);
  color: var(--landingTextDark);

  & > span {
    ${hrefStyles}
  }
`;

const Footer = () => {
  return (
    <Container>
      <Guides color="rgba(0,0,0,0)" />
      <Content>
        <Item>
          <SvgLogo height={70} dark ignoreDarkMode />
          <LogosContainer>
            <a href="https://www.instagram.com/vatsimnet">
              <Instagram size={32} strokeWidth={1.5} />
            </a>
            <a href="https://www.facebook.com/vatsimnet/">
              <Facebook size={32} strokeWidth={1.5} />
            </a>
            <a href="https://twitter.com/vatsim">
              <Twitter size={32} strokeWidth={1.5} />
            </a>
            <a href="https://github.com/vatsimnetwork">
              <GitHub size={32} strokeWidth={1.5} />
            </a>
          </LogosContainer>
          <Copyright>&copy; {new Date().getFullYear()} VATSIM Inc.<br />All rights reserved.</Copyright>
        </Item>
        <Item>
          <Label>Sites</Label>
          <Link href="https://support.vatsim.net/">
            <span>Support Helpdesk</span>
          </Link>
          <Link href="https://community.vatsim.net/">
            <span>Community Discord</span>
          </Link>
          <Link href="https://my.vatsim.net/">
            <span>MyVATSIM</span>
          </Link>
          <Link href="https://stats.vatsim.net/">
            <span>Statistics</span>
          </Link>
          <Link href="https://forum.vatsim.net/">
            <span>Forums</span>
          </Link>
          <Link href="https://network-status.vatsim.net/">
            <span>Network Status</span>
          </Link>
        </Item>
        <FlexPlaceHolder visibleBelow="1000px" />
        <Item>
          <Label>Organisation</Label>
          <Link href="https://vats.im/logo">
            <span>Logos</span>
          </Link>
          <Link href="https://vats.im/brand">
            <span>Brand Guidelines</span>
          </Link>
        </Item>
      </Content>
    </Container>
  );
};

export default Footer;
